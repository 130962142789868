import React, { useEffect, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import queryString from "query-string";

import Card from "../../../../../components/Card";
import Typography from "../../../../../base-components/Typography";
import Input from "../../../../../base-components/Input";
import IconButton from "../../../../../base-components/IconButton";
import Button from "../../../../../base-components/Button";

import { ReactComponent as Search } from "../../../../../assets/icon/search.svg";

const PlateAndVin = ({ searchVehicleInfo = () => {}, loading }) => {
  const {
    getValues,
    formState: { errors },
  } = useFormContext();
  const [value, setValue] = useState("Улсын");
  const [error, setError] = useState("");

  const searchingInfo = (e) => {
    if (value === "Арлын") {
      let data = {
        cabin_number: getValues("cabin_number"),
        plate_number: getValues("plate_number"),
      };
      const parameter = queryString.stringify(data);
      searchVehicleInfo(parameter);
    } else if (value === "Улсын") {
      const plate_number = getValues("plate_number");
      if (
        !/^([0-9]{4}[А-ЯЁӨҮ]{2,3})|([А-ЯЁӨҮ]{2,3}[0-9]{4})$/i.test(plate_number)
      ) {
        setError("Улсын бүртгэлийн дугаар буруу байна!");
      } else {
        setError("");
        let data = {
          cabin_number: getValues("cabin_number"),
          plate_number: getValues("plate_number"),
        };
        const parameter = queryString.stringify(data);
        searchVehicleInfo(parameter);
      }
    }
    e.preventDefault();
  };

  return (
    <>
      {value === "" ? (
        <div className="mt-4 flex items-center gap-4 max-[500px]:flex-col">
          <Card onClick={() => setValue("Улсын")}>
            <Typography variant="Label">Улсын дугаараар</Typography>
          </Card>
        </div>
      ) : (
        <div className="mt-2 py-4">
          <Typography variant="Title" size="md">
            {value} дугаар оруулна уу?
          </Typography>
          <div
            className={twMerge([
              "relative mt-4 flex items-center rounded-2xl border pr-6 md:pr-10",
              "max-sm:pr-0",
            ])}
          >
            {value === "Улсын" ? (
              <Plate {...{ loading }} />
            ) : (
              <Vin {...{ loading }} />
            )}
            <IconButton
              id="search_id"
              disabled={loading}
              variant={"primary"}
              onClick={searchingInfo}
              // type="button"
              className="h-14 w-14 max-sm:hidden"
            >
              <Search fill={loading ? "#BBC1C9" : "#fff"} />
            </IconButton>
          </div>
          {errors?.xyp?.type === "custom" && (
            <p className="mt-2 pl-2 text-sm text-error">
              {errors?.xyp?.message}
            </p>
          )}
          {error && (
            <p className="mt-2 pl-2 text-sm text-error">
              {error}
            </p>
          )}
          <div className="mt-8 hidden justify-end max-sm:flex">
            <Button
              variant="outlinePrimary"
              icon={<Search className="h-[18px] w-[18px]" />}
              disabled={loading}
              onClick={searchingInfo}
              // type="button"
            >
              Хайх
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

let currentIndex = 0;

const Plate = ({ loading }) => {
  const {
    formState: { errors },
    setValue,
    watch,
    setError,
    clearErrors,
    trigger,
  } = useFormContext();
  const [plateNumber, setPlateNumber] = useState();

  const handleOnChange = ({ target }) => {
    const val = target.value.toUpperCase();
    const cyrillicPattern = /^[0-9А-ЯЁӨҮ]+$/;
    if (cyrillicPattern.test(val) || val === "") {
      setValue("plate_number", val);
    }
  };
  return (
    <>
      <Input
        placeholder="Улсын дугаар"
        disabled={loading}
        onChange={handleOnChange}
        maxLength={7}
        value={watch("plate_number")}
        className="grow rounded-2xl border-none p-6 text-2xl shadow-none disabled:bg-white max-sm:text-base max-sm:tracking-[0.25px]"
        width="min-w-[500px] max-sm:min-w-full "
      />
      {errors?.plate_number?.type === "custom" && (
        <p className="absolute bottom-2 mt-2 pl-2 text-sm text-error">
          {errors?.plate_number?.message}
        </p>
      )}
    </>
  );
};

const Vin = ({ loading }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <Input
        placeholder="Арлын дугаар (заавал биш)"
        disabled={loading}
        {...register("cabin_number", {
          required: true,
        })}
        className="grow rounded-2xl border-none p-6 text-2xl shadow-none disabled:bg-white max-sm:text-base max-sm:tracking-[0.25px]"
        width="min-w-[500px] max-sm:min-w-full "
      />
      {errors?.cabin_number?.type === "required" && (
        <p className="absolute bottom-2 left-6 mt-2 pl-2 text-sm text-error">
          Арлын дугаараа оруулна уу.
        </p>
      )}
    </>
  );
};

export default PlateAndVin;
