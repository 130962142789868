import { useEffect, useState } from "react";

import RadioButton from "../../../../base-components/RadioButton";
import Typography from "../../../../base-components/Typography";
import AlertModal from "../../../../base-components/AlertModal";
import Input from "../../../../base-components/Input";
import Button from "../../../../base-components/Button";
import Modal from "../../../../base-components/Modal";

import BankAccount from "./types/BankAccount";
import Qpay from "../../../../components/Qpay";

import PaymentService from "../../../../services/PaymentService";
import InvoiceService from "../../../../services/InvoiceService";
import EbarimtService from "../../../../services/EbarimtService";
import { useStore } from "../../../../stores";

import { formatNumber } from "../../../../utils/helper";

const Payment = ({ policyId, amount, qr_image, sequence }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [content, setContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [isIndividual, setIsIndividual] = useState("person");
  const [qpayMobile, setQpayMobile] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [ebarimtCompanyName, setEbarimtCompanyName] = useState("");
  const [ebarimtCompanyError, setEbarimtCompanyError] = useState(false);
  const [qpayMobileError, setQpayMobileError] = useState("");
  const [registrationNumberError, setRegistrationNumberError] = useState("");
  const [personRegistrationNumber, setPersonRegistrationNumber] = useState("");
  const [personRegistrationNumberError, setPersonRegistrationNumberError] =
    useState("");
  const [inputMaxLength, setInputMaxLength] = useState(7);

  // И баримт төлөгчийн төлийг солиход input clear нийнэ
  useEffect(() => {
    setEbarimtCompanyName("");
    setEbarimtCompanyError(false);
    if (isIndividual !== "person") setQpayMobile("");
    if (isIndividual !== "company") setRegistrationNumber("");
    if (isIndividual !== "companyPerson") setPersonRegistrationNumber("");
  }, [registrationNumber, isIndividual, personRegistrationNumber, qpayMobile]);

  // Төлбөр төлөгдсөн эсхийг шалгах function
  const handleCheckInvoice = () => {
    PaymentService.checkInvoice(policyId)
      .then((response) => {
        if (response.status === "success" && response.state === "paid") {
          setIsShowAlert(true);
          setIsOpenModal(false);
        } else {
          useStore.setState({
            notification: {
              message: "Төлбөр төлөгдөөгүй байна.",
              type: "warning",
            },
          });
        }
      })
      .catch((error) => {
        useStore.setState({
          notification: {
            message: "Qpay үүсгэх явцад алдаа.",
            type: "error",
          },
        });
      });
  };

  const payments = [
    {
      value: "Qpay-ээр төлөх",
      icon: "/payment/qpay.png",
      content: <Qpay {...{ qr_image, onCheckInvoice: handleCheckInvoice }} />,
      title: "QR Код Уншуулах",
    },
    {
      value: "Дансаар шилжүүлэх",
      icon: "/payment/arrow-swap-rec.png",
      content: <BankAccount {...{ sequence, amount }} />,
      title: "Дансруу шилжүүлэх",
    },
  ];

  // Өөр төлөгчийн мэдээлэл илгээх function
  const dataPayerData = () => {
    const payload = {
      policy_id: policyId,
      mobile: qpayMobile,
      registrationNumber:
        isIndividual === "company"
          ? registrationNumber
          : personRegistrationNumber,
    };
    // хувь хүн бол зөвхөн дугаар авна Бизнес эрхлэгчийн бол регистер авна
    if (isIndividual === "person") payload.registrationNumber = null;
    else payload.mobile = null;

    InvoiceService.insertOwnerInfo(payload)
      .then((response) => {
        setIsOpenModal(true);
      })
      .catch((error) => {
        useStore.setState({
          notification: {
            message: "И баримтын мэдээлэл илгээхэд алдаа гарлаа.",
            type: "error",
          },
        });
      });
  };

  // Ebarimt Бизнес эрхлэгчийн нэр авах service function
  const getCompanyName = async (companyRegister) => {
    if (!companyRegister || isIndividual === "person") return;

    try {
      const response = await EbarimtService.getTinInfo(companyRegister);
      if (response.status === 200) {
        setEbarimtCompanyName(response?.data?.name || "");
      } else {
        setEbarimtCompanyError(true);
        useStore.setState({
          notification: {
            message:
              response?.status === 500
                ? "Бизнес эрхлэгчийн регистрийн дугаар буруу байна"
                : "Алдаа гарлаа. Дахин оролдоно уу.",
            type: "warning",
          },
        });
      }
    } catch (error) {
      useStore.setState({
        notification: {
          message: "Бизнес эрхлэгчийн мэдээлэл татах явцад алдаа гарлаа.",
          type: "error",
        },
      });
    }
  };

  // Төлбөр төлөх button дарах үед ажиллах function
  const selectPaymentType = (data) => {
    let hasError = false;
    if (isIndividual === "person") {
      if (qpayMobile.length === 0) {
        setQpayMobileError("Гар утасны дугаар заавал оруулах шаардлагатай.");
        hasError = true;
      } else if (qpayMobile.length < 8) {
        setQpayMobileError("Гар утасны дугаар 8 оронтой байх ёстой.");
        hasError = true;
      } else {
        setQpayMobileError("");
      }
    } else if (isIndividual === "company") {
      if (registrationNumberError) {
        hasError = true;
      } else if (ebarimtCompanyError) {
        setRegistrationNumberError(
          "Байгууллагын регистрийн дугаар буруу байна.",
        );
        hasError = true;
      } else if (registrationNumber.length === 0) {
        setRegistrationNumberError(
          "Байгууллагын регистрийн дугаар заавал оруулах шаардлагатай.",
        );
        hasError = true;
      } else if (registrationNumber.length < 7) {
        setRegistrationNumberError(
          "Байгууллагын регистрийн дугаар 7 оронтой байх шаардлагатай",
        );
        hasError = true;
      } else {
        setRegistrationNumberError("");
      }
    } else if (isIndividual === "companyPerson") {
      if (personRegistrationNumberError) {
        hasError = true;
      } else if (ebarimtCompanyError) {
        setPersonRegistrationNumberError(
          "Бизнес эрхлэгчийн регистрийн дугаар буруу байна.",
        );
        hasError = true;
      } else if (personRegistrationNumber.length === 0) {
        setPersonRegistrationNumberError(
          "Бизнес эрхлэгчийн регистрийн дугаар заавал оруулах шаардлагатай.",
        );
        hasError = true;
      } else if (personRegistrationNumber.length < 10) {
        setPersonRegistrationNumberError(
          "Бизнес эрхлэгчийн регистрийн дугаар 10 оронтой байх шаардлагатай",
        );
        hasError = true;
      } else {
        setPersonRegistrationNumberError("");
      }
    }
    if (!hasError) {
      setContent(data.content);
      setModalTitle(data.title);

      if (qpayMobile || registrationNumber || personRegistrationNumber) {
        dataPayerData();
      }
    }
  };
  const isNumber = (value) => {
    return typeof value === "number";
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const btnClick = () => {
    setIsShowAlert(false);
    window.location.reload();
  };

  return (
    <>
      <div className="text-primary">
        <Typography variant="Label" size="lg">
          Нийт дүн:
        </Typography>
        <Typography variant="Title" size="lg" className="mt-5">
          {formatNumber(amount)} ₮
        </Typography>
        <Typography variant="Label" size="md" className="mt-5">
          Ибаримт авах{" "}
          {isIndividual === "person" ? "хувь хүний" : "бизнес эрхлэгчийн"}{" "}
          мэдээлэл
        </Typography>
        <div className="just mt-2 flex flex-col justify-between md:flex-row">
          <RadioButton
            id="payment_person_radio_button"
            label={
              <Typography variant="Body" size="lg">
                Хувь хүн
              </Typography>
            }
            checked={"person" === isIndividual}
            value={"person"}
            onChange={(e) => setIsIndividual(e.target.value)}
          />
          <RadioButton
            id="payment_company_radio_button"
            label={
              <div className="flex flex-wrap justify-start">
                <Typography variant="Body" size="lg">
                  Байгууллага
                </Typography>
              </div>
            }
            checked={"company" === isIndividual}
            value={"company"}
            onChange={(e) => setIsIndividual(e.target.value)}
          />
          <RadioButton
            id="payment_company_person_radio_button"
            label={
              <div className="flex flex-wrap justify-start">
                <Typography variant="Body" size="lg">
                  Бизнес эрхлэгч
                </Typography>
              </div>
            }
            checked={"companyPerson" === isIndividual}
            value={"companyPerson"}
            onChange={(e) => setIsIndividual(e.target.value)}
          />
        </div>
        <div className="mt-2">
          {isIndividual === "person" && (
            <>
              <Input
                width="w-full"
                id="qpay_mobile"
                label="Гар утасны дугаар"
                maxLength={8}
                type="number"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length > 8) setQpayMobile(value.slice(0, 8));
                  else setQpayMobile(value);
                  setQpayMobileError("");
                }}
              />
              {qpayMobileError && (
                <Typography variant="Body" size="sm" className="text-red-500">
                  {qpayMobileError}
                </Typography>
              )}
            </>
          )}
          {isIndividual === "company" && (
            <div>
              <div>
                <>
                  <Input
                    width="w-full"
                    type="number"
                    id="registrationNumber"
                    label="Байгууллагын регистрийн дугаар"
                    maxLength={7}
                    onChange={(e) => {
                      let value = e.target.value;
                      const isValidNumber = /^\d{1,8}$/.test(value);
                      if (isValidNumber && value.length <= 7) {
                        setRegistrationNumber(value);
                        setRegistrationNumberError("");
                        setInputMaxLength(7);
                        if (value.length === 7) {
                          getCompanyName(value);
                        }
                      } else if (isValidNumber && value.length < 7) {
                        setRegistrationNumber(value);
                        setRegistrationNumberError(
                          "Буруу формат. 7 оронтой тоо байх ёстой",
                        );
                      }
                    }}
                  />
                  {registrationNumberError && (
                    <div className="mt-1">
                      <Typography
                        variant="Body"
                        size="sm"
                        className="text-red-500"
                      >
                        {registrationNumberError}
                      </Typography>
                    </div>
                  )}
                </>
              </div>
              <div className="mt-4">
                <>
                  <Input
                    width="w-full"
                    type="text"
                    id="companyName"
                    label="Байгууллагын нэр"
                    disabledV2={true}
                    value={ebarimtCompanyName}
                  />
                </>
              </div>
            </div>
          )}
          {isIndividual === "companyPerson" && (
            <div>
              <div>
                <>
                  <Input
                    width="w-full"
                    type="text"
                    id="registrationNumberPerson"
                    label="Бизнес эрхлэгч регистрийн дугаар"
                    maxLength={10}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (/^[А-Яа-я]{2}/.test(value)) {
                        value =
                          value.slice(0, 2).toUpperCase() + value.slice(2);
                      }
                      const isValidString = /^[А-Я]{2}\d{0,8}$/.test(value);

                      if (isValidString && value.length <= 10) {
                        setPersonRegistrationNumber(value);
                        setPersonRegistrationNumberError("");
                        setInputMaxLength(10);

                        if (value.length === 10) {
                          getCompanyName(value);
                        }
                      } else if (value.length === 0) {
                        setPersonRegistrationNumber(value);
                        setPersonRegistrationNumberError(
                          "Бизнес эрхлэгчийн регистрийн дугаар заавал оруулах шаардлагатай.",
                        );
                      } else if (
                        (/^[А-Яа-я]/.test(value) && !isValidString) ||
                        !isNumber(value)
                      ) {
                        setPersonRegistrationNumber(value);
                        setPersonRegistrationNumberError(
                          "Регистрийн дугаар РДXXXXXXXX байх шаардлагатай",
                        );
                      }
                    }}
                  />
                  {personRegistrationNumberError && (
                    <div className="mt-1">
                      <Typography
                        variant="Body"
                        size="sm"
                        className="text-red-500"
                      >
                        {personRegistrationNumberError}
                      </Typography>
                    </div>
                  )}
                </>
              </div>
              <div className="mt-4">
                <>
                  <Input
                    width="w-full"
                    type="text"
                    id="companyName"
                    label="Бизнес эрхлэгчийн нэр"
                    disabledV2={true}
                    value={ebarimtCompanyName}
                  />
                </>
              </div>
            </div>
          )}
        </div>
        <div className="mt-4 flex items-center gap-5 max-[500px]:flex-col">
          {payments.map((data, index) => (
            <div
              key={index}
              className="w-52 cursor-pointer rounded-xl border border-stroke-dark-12 bg-surface-gray p-4"
              onClick={() => selectPaymentType(data)}
            >
              <img src={data.icon} alt="qpay" className="h-10 w-10" />
              <Typography variant="Title" size="md" className="mt-12">
                {data.value}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      <Modal
        {...{ isOpenModal }}
        title={modalTitle}
        onClose={closeModal}
        size="lg"
      >
        {content}
      </Modal>
      <AlertModal
        isOpen={isShowAlert}
        type="success"
        title="Төлбөр амжилттай төлөгдлөө"
        message="Танд баярлалаа! Таны цахим гэрээ манай цахим бүртгэлийн системд нэмэгдлээ"
        footer={
          <Button type="button" onClick={btnClick}>
            Үргэлжлүүлэх
          </Button>
        }
      />
    </>
  );
};

export default Payment;
