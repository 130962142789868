import Service from "./BaseService";

const getQpayInvoice = (policy_id) =>
  Service.get(`/dynamicInsurance/get-qpay-invoice?policy_id=${policy_id}`);

const checkInvoice = (policy_id, config) =>
  Service.get(`/dynamicInsurance/check-invoice?policy_id=${policy_id}`, {
    ...config,
    nonLoading: true,
  });

const getQpayCallback = (policy_id) =>
  Service.get(`/dynamicInsurance/get-qpay-callback?policy_id=${policy_id}`);

const getPaidStatus = (policy_invoice) =>
  Service.get(
    `/dynamicInsurance/policy-invoice?policy_invoice=${policy_invoice}`,
  );

const PaymentService = {
  getQpayInvoice,
  getPaidStatus,
  getQpayCallback,
  checkInvoice,
};

export default PaymentService;
